
.pagination {
  display: flex;
  margin: 0 auto;
  margin-top:20px;
  justify-content: center;

  & > li {
      margin: 0 12px;
      list-style: none;
      & > a {
          position: relative;
          font-size: 0.9rem;
          width: 24px;
          height: 24px;
          outline: none;
          z-index: 100;
          cursor: pointer;

          &::before {
              content: "";
              display: block;
              position: absolute;
              top: 50%;
              left: 50%;
              width: 26px;
              height: 26px;
              border-radius: 50%;
              transform: translate(-50%, -50%);
              z-index: -100;
          }

          &:hover {
              &::before {
                  background-color: rgba(0, 0, 0, 0.16);
              }
          }
      }

      &.active {
          & > a::before {
              background-color: rgba(0,86,0,1);
          }
          color:#FFFFFF;
      }
  }

  &__previous,
  &__next {
      & > a {
          font-size: 0.7rem;
      }
  }

  &__disabled {
      display: none;
  }
}